<!--SearchListItem:搜索列表项 2024/07/28 15:41:23 huangmx-->

<template>
  <div class="search-list-item">
    <div class="search-item-feed">
      <div class="image" v-if="item.coverList && item.coverList.length === 1"
        :style="{ backgroundImage: 'url(' + item.covers + ')' }">
      </div>
      <div class="item-info">
        <h3 class="item-title">{{ item.title }}</h3>
        <p class="item-content">{{ item.summary }}</p>
        <div class="item-user"><span>{{ item.authorInfo.name }}</span> <span>{{ item.gmtCreate | formatDate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SearchListItem',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      tip: 'Hi, 新的组件'
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    xxxAction: function (action) {
      console.log(action)
    }
  }
}
</script>

<style scoped>
.search-item-feed {
  background-color: rgb(255, 255, 255);
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  margin-bottom: 15px;
  padding: 30px;
  position: relative;
  width: 100%;
}

.search-item-feed .image {
  background-position: 50%;
  margin-right: 20px;
  flex: 0 0 180px;
  height: 102px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
}

.search-item-feed .item-info {
  position: relative;
  height: 102px;
}

.search-item-feed .item-title {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
  color: rgb(82, 82, 82);
}

.search-item-feed .item-content {
  font-size: 14px;
  line-height: 20px;
  color: rgb(82, 82, 82);
  overflow: hidden;
  height: 40px;
  text-overflow: ellipsis;
}

.search-item-feed .item-user {
  font-size: 12px;
  position: absolute;
  left: 0;
  color: rgb(155, 155, 155);
  bottom: -5px;
}
</style>
