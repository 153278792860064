<!--index:搜索结果页 2024/07/06 16:42:54 YuAng-->

<template>
  <div class="search-index page-center-content">
    <div class="search-area">
      <el-input v-model="keyword" :placeholder="$t('keywordSearchTip')" prefix-icon="el-icon-search"
        @change="changeKeyword"></el-input>
    </div>
    <div class="search-types">
      <ul class="type-list">
        <li class="type-item" :class="{ 'active': activeType === 'content' }" @click="changeType('content')">{{
        $t('searchContent') }}</li>
        <li class="type-item" :class="{ 'active': activeType === 'user' }" @click="changeType('user')">
          {{ $t('searchUser') }}</li>
      </ul>
    </div>
    <div class="search-result" v-infinite-scroll="getData" :infinite-scroll-disabled="!loadMore"
      :infinite-scroll-immediate=false :infinite-scroll-distance=30>
      <div v-if="!list || list.length === 0" class="nodata">
        <img src="../../assets/images/nodata.png" alt="nodata" />
        <p>{{ $t('noContentText') }}</p>
      </div>
      <div class="list-parent" v-else>
        <div :class="{ 'user-item': activeType === 'user' }" v-for="(item, index) in list"
          :key="'search' + item.id + index">
          <search-list-item v-if="activeType === 'content'" :item="item"
            @click.native="$router.push('/post/' + item.id)"></search-list-item>
          <div v-else>
            <head-common :item="item"></head-common>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchListItem from './components/SearchListItem.vue'
import HeadCommon from '@/views/components/HeadCommon.vue'
import { searchApi } from '@/utils/api'
export default {
  name: 'index',
  components: {
    SearchListItem,
    HeadCommon
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      keyword: '',
      currentPage: 1,
      isloading: false,
      loadMore: true,
      activeType: 'content',
      list: []
    }
  },
  created () {
    this.keyword = this.$route.query.key
  },
  mounted () {
    this.getData()
  },
  methods: {
    changeType: function (activeType) {
      this.activeType = activeType
      this.currentPage = 1
      this.list = []
      this.getData()
    },
    changeKeyword: function () {
      this.currentPage = 1
      this.getData()
    },
    getData: function () {
      if (this.activeType === 'content') {
        this.getArticles()
      } else if (this.activeType === 'user') {
        this.getUsers()
      }
    },
    getUsers: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      searchApi.searchUser({
        keyword: this.keyword,
        currentPage: this.currentPage,
        pageSize: 10
      }).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200) {
          this.currentPage++
          console.log('搜索结果', resp.data)
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          this.list = this.list.concat(resp.data)
        }
      }).catch(err => {
        console.log(err)
        this.isloading = false
      })
    },
    getArticles: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      searchApi.searchArticle({
        keyword: this.keyword,
        currentPage: this.currentPage,
        pageSize: 10
      }).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200) {
          this.currentPage++
          console.log('搜索结果', resp.data)
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          this.list = this.list.concat(resp.data)
        }
      }).catch(err => {
        console.log(err)
        this.isloading = false
      })
    }
  }
}
</script>

<style scoped>
.search-index {
  margin-top: 40px;
}

.search-area {
  display: flex;
  align-items: center;
  height: 60px;
}

.search-types {
  margin-top: 50px;
}

.type-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.type-item {
  cursor: pointer;
  padding: 10px 0;
  margin-right: 30px;
  font-size: 16px;
  transition: color 0.3s ease;
  position: relative;
}

.type-item.active {
  color: rgb(19, 128, 240);
}

.type-item.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  border-radius: 3px;
  background-color: rgb(19, 128, 240);
}

.search-result {
  margin: 30px 0;
  min-height: 1000px;
  overflow-y: auto;
}

.nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nodata img {
  width: 384px;
  height: 384px;
}

.search-area>>>.el-input,
.search-area>>>.el-input--prefix .el-input__inner {
  height: 80px;
}

.search-area>>>.el-input__inner {
  border-radius: 14px;
  font-size: 20px;
  padding-left: 50px;
}

.search-area>>>.el-icon-search {
  font-size: 24px;
  margin-left: 15px;
}

.list-parent {
  position: relative
}

.user-item {
  position: relative;
  padding-top: 20px;
  cursor: pointer;
}

.user-item:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px dashed rgb(185, 185, 185);
}
</style>
